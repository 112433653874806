import ImagesUrl from "../../assets/Index";

export const ClientDetailsData = [
        {
          id:1,
          imgUrl:ImagesUrl.clientDetail.clientsAshok_jade,
          title:"Ashok Jade",
          subTitle:"CEO of Shalimar Paints",
          desc:"Hexlog Infotech Pvt Ltd has carried out a really excellent and highly professional job. The team's exceptional domain and technical knowledge helped convert our requirement, which was just in the form of ideas, into a very high-end enterprise mobility solution, with real-time interface with our SAP system.",
        },
        {
          id:2,
          imgUrl:ImagesUrl.clientDetail.clientsDinesh_gupta,
          title:"Dinesh C. Gupta",
          subTitle:"Director of Capital Power Systems Ltd",
          desc:"My ERP software had to be easy to use & Hexlog Infotech Pvt Ltd made that happen for me! I am dedicatedly using this software from past 2 years & not once have I encountered any problem. It also has an impeccable support team & that’s why I recommend it to others also.",
        },
        {
          id:3,
          imgUrl:ImagesUrl.clientDetail.clientsRanjit_singh,
          title:"Ranjith Singh",
          subTitle:"V.P of Arihant Electricals Pvt Ltd",
          desc:"I very much appreciate the team's EFFORTs over these past years. Your experience and dedicated staff have really helped us in implementing the solution and provided a helping hand whenever there is a query raised. ",
        },
        { 
          id:4,
          imgUrl:ImagesUrl.clientDetail.clientsAtal_vihari,
          title:"Atal Bihari",
          subTitle:"Sales Head of Vision Plus Pvt Ltd",
          desc:"Vision Plus Pvt Ltd Is using Qkrbiz from July 2015 and I am really impressed with the product features. We have streamlined our Sales and Services to a great extent and kudos to EFFORT for helping us achieve this. I highly recommend to use this World class product. ",
        }
  ]
import ImagesUrl from "../../assets/Index";

export const CustomersData = [
   {
      id:"1",
      title:"Trusted by",
      desc:"85000+ users trust & love Qkrbiz",
      CustomersList: [
         {
            id:1,
            imgUrl: ImagesUrl.client.ClientsImage1,
            alt:"Indian Institute of Technology Roorkee",
            desc:"Indian Institute of Technology Roorkee",
            unsetBorder: ""
         },
         {
            id:2,
            imgUrl: ImagesUrl.client.ClientsImage2,
            alt:"Indian Institute of Technology (BHU) Varanasi",
            desc:"Indian Institute of Technology (BHU) Varanasi",
            unsetBorder: ""
      
         },
         {
            id:3,
            imgUrl: ImagesUrl.client.ClientsImage3,
            alt:"Shalimar Paints Ltd.",
            desc:"Shalimar Paints Ltd.",
            unsetBorder: ""
      
         },
         {
            id:4,
            imgUrl: ImagesUrl.client.ClientsImage4,
            alt:"Capital Power Systems Ltd.",
            desc:"Capital Power Systems Ltd.",
            unsetBorder: ""
      
         },
         {
            id:5,
            imgUrl: ImagesUrl.client.ClientsImage5,
            alt:"Bihar State Electronics",
            desc:"Bihar State Electronics Development Corportaion Ltd.",
            unsetBorder: ""
      
         },
         {
            id:6,
            imgUrl: ImagesUrl.client.ClientsImage6,
            alt:"Swastik Agro Products (MAHI MANGO)",
            desc:"Swastik Agro Products (MAHI MANGO)",
            unsetBorder: "border-right-unset"
      
         },
         {
            id:7,
             imgUrl: ImagesUrl.client.ClientsImage7,
            alt:"Alacrit Retail Pvt. Ltd.",
            desc:"Alacrit Retail Pvt. Ltd.",
            unsetBorder: ""
      
         },
         {
            id:8,
            imgUrl: ImagesUrl.client.ClientsImage8,
            alt:"Hitech Enviro Engineers",
            desc:"Hitech Enviro Engineers & Consultants Private Limited",
            unsetBorder: ""
      
         },
         {
            id:9,
            imgUrl: ImagesUrl.client.ClientsImage9,
            alt:"Vision Plus Global Services",
            desc:"Vision Plus Global Services",
            unsetBorder: ""
         },
         {
            id:10,
            imgUrl: ImagesUrl.client.ClientsImage10,
            alt:"DSK Enterprises",
            desc:"DSK Enterprises",
            unsetBorder: ""
      
         },
         {
            id:11,
            imgUrl: ImagesUrl.client.ClientsImage11,
            alt:"Raj Group of industries",
            desc:"Raj Group of industries",
            unsetBorder: ""
      
         },
         {
            id:12,
             imgUrl: ImagesUrl.client.ClientsImage12,
            alt:"Kingmaker Insence Stick",
            desc:"Kingmaker Insence Stick",
            unsetBorder: "border-right-unset"
      
         },
         {
            id:13,
             imgUrl: ImagesUrl.client.ClientsImage13,
            alt:"KD Electrical Pvt Ltd Industry- Electrical",
            desc:"KD Electrical Pvt Ltd Industry- Electrical",
            unsetBorder: ""
      
         },
         {
            id:14,
            imgUrl: ImagesUrl.client.ClientsImage14,
            alt:"Nexgen Techtronics Private Limited ",
            desc:"Nexgen Techtronics Private Limited ",
            unsetBorder: ""
      
         },
         {
            id:15,
            imgUrl: ImagesUrl.client.ClientsImage15,
            alt:"Bhanu Prakash Soap Work",
            desc:"Bhanu Prakash Soap Work",
            unsetBorder: ""
      
         },
         {
            id:16,
             imgUrl: ImagesUrl.client.ClientsImage16,
            alt:"Hindustan Herbal Pvt Ltd ",
            desc:"Hindustan Herbal Pvt Ltd ",
            unsetBorder: ""
      
         },
         {
            id:17,
             imgUrl: ImagesUrl.client.ClientsImage17,
            alt:"Pousny Food and Beverages Pvt Ltd ",
            desc:"Pousny Food and Beverages Pvt Ltd",
            unsetBorder: ""
      
         },
         {
            id:18,
             imgUrl: ImagesUrl.client.ClientsImage18,
            alt:"Fire Solution ",
            desc:"Fire Solution ",
            unsetBorder: "border-right-unset"
      
         },
         {
            id:19,
             imgUrl: ImagesUrl.client.ClientsImage19,
            alt:"Kothari Tek Solution Pvt. Ltd.",
            desc:"Kothari Tek Solution Pvt. Ltd.",
            unsetBorder: ""
      
         },
         {
            id:20,
             imgUrl: ImagesUrl.client.ClientsImage20,
            alt:"Regard Network Solution",
            desc:"Regard Network Solution",
            unsetBorder: ""
         },
         {
            id:21,
            imgUrl: ImagesUrl.client.ClientsImage21,
            alt:"S K Enterprises Pvt. Ltd.",
            desc:"S K Enterprises Pvt. Ltd.",
            unsetBorder: "border-right-unset"
         },
      ]
   }    
  ]
import React, { useRef, useEffect } from 'react';
import "./VideoWindowPlay.scss";

const VideoWindowPlay = ({ videoSource, videoPoster, videoShadow, radius }) => {

   const videoRef = useRef(null);

   const platform = navigator.platform;
   const isVisible = platform === 'iPhone' || platform === 'iPad' || platform.includes('Mac') ;

  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 0.5, // Adjust this threshold as needed
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, options);
  //   if (videoRef.current) {
  //     observer.observe(videoRef.current);
  //   }

  //   return () => {
  //     if (videoRef.current) {
  //       observer.unobserve(videoRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this threshold as needed
    };
  
    const observer = new IntersectionObserver(handleIntersection, options);
    const currentVideoRef = videoRef.current; // Store current value of videoRef.current in a variable
  
    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }
    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, [videoRef]);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error("Autoplay failed:", error);
        });
      } else if (videoRef.current) {
        videoRef.current.pause();
      }
    });
  };  
 
  return (
    <>
      {isVisible  ? (
        <img
          src={videoPoster}
          className="img-fluid"
          alt="Poster"
        />         
        ) :(
          <video
          className={`video-player ${videoShadow} ${radius}`} 
          ref={videoRef} 
          controls 
          muted>
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      } 
    </>
  );
};

export default VideoWindowPlay;



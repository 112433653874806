
export const QkrbizSchemaData = [
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Qkrbiz",
    "url": "https://www.qkrbiz.com",
    "logo": "https://www.qkrbiz.com/static/media/Qkrbiz.5246f2268bf6d8c54e1e.svg",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91-11-46522453",
      "contactType": "Customer Service",
      "areaServed": "IN",
      "availableLanguage": "English"
    },
    "sameAs": [
      "https://www.linkedin.com/products/qkrbiz",
      "https://www.facebook.com/quikrbizERP"
     ],
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "New Delhi",
      "postalCode": "110019",
      "streetAddress": "7th Floor, Manjusha Building, 403, 57, Nehru Place",
      "addressCountry": "IN"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "ratingCount": "100"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://www.qkrbiz.com/hrms-software",
      "highPrice": "150",
          "lowPrice": "40",
      "priceCurrency": "INR",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock"
    },
     "member": [
      {
        "@type": "WebApplication",
        "name": "QkrHR",
        "url": "https://www.qkrbiz.com/hrms-software",
        "applicationCategory": "HRSoftware",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "url": "https://www.qkrbiz.com/hrms-software",
         "highPrice": "150",
          "lowPrice": "40",
          "priceCurrency": "INR",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.7",
          "ratingCount": "50"
        }
      },
      {
        "@type": "WebApplication",
        "name": "QkrDesk",
        "url": "https://www.qkrbiz.com/qkrdesk",
        "applicationCategory": "Customer Support Software",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "url": "https://www.qkrbiz.com/qkrdesk",
          "price": "Not disclosed",
          "priceCurrency": "INR",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.6",
          "ratingCount": "30"
        }
      },
      {
        "@type": "WebApplication",
        "name": "QkrVisit",
        "url": "https://www.qkrbiz.com/qkrvisit",
        "applicationCategory": "Field Force Automation Software",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "url": "https://www.qkrbiz.com/qkrvisit",
          "price": "Not disclosed",
          "priceCurrency": "INR",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "ratingCount": "20"
        }
      },
      {
        "@type": "WebApplication",
        "name": "QkrExpense",
        "url": "https://www.qkrbiz.com/qkrexpense",
        "applicationCategory": "ExpenseManagement",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "url": "https://www.qkrbiz.com/qkrexpense",
          "price": "Not disclosed",
          "priceCurrency": "INR",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.5",
          "ratingCount": "25"
        }
      },
      {
        "@type": "WebApplication",
        "name": "QkrSales",
        "url": "https://www.qkrbiz.com/qkrsales",
        "applicationCategory": "SalesSoftware",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "url": "https://www.qkrbiz.com/qkrsales",
          "price": "Not disclosed",
          "priceCurrency": "INR",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.4",
          "ratingCount": "35"
        }
      },
      {
        "@type": "WebApplication",
        "name": "QkrTenders",
        "url": "https://www.qkrbiz.com/qkrtenders",
        "applicationCategory": "TenderManagement",
        "operatingSystem": "Web",
        "offers": {
          "@type": "Offer",
          "url": "https://www.qkrbiz.com/qkrtenders",
          "price": "Not disclosed",
          "priceCurrency": "INR",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.6",
          "ratingCount": "15"
        }
      }
    ]  
  }   
]



import React from 'react';
import './Loading.scss';
import ImagesUrl from '../../assets/Index';

const styles = {
  LoaderHeight: {
    width: "6rem",
    Height: "6rem",
  },
} 

 const Loading = () => {
  return (
    <>
        <div className="loader">
            <img className="img-fluid" src={ImagesUrl.QkrbizLoader} style={styles.LoaderHeight} alt="Loader" />
        </div>
    </>
  )
}
export default Loading;

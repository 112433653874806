import React, { Fragment } from 'react';
import "./Clients.scss";
import Container from 'react-bootstrap/Container';
import Slider from "react-slick";
import { CustomersData } from '../../Pages/Customers/CustomersData';
const Clients = () => {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: false,
    centerPadding:'10px',
    responsive: [
    {
      breakpoint: 992,
      settings: {
      slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
      slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
      slidesToShow: 2
      }
    },
    {
      breakpoint: 320,
      settings: {
      slidesToShow: 1
      }
    }
    ]
  };
   return(
    <>
      <div className="clients">
          <Container>
              {CustomersData.map((cusList, cusIndex) =>(
                <Fragment key={cusIndex}>
                    <div className="row">
                        <div className="col-lg-12 text-center mb-4">
                            <h2 className="mb-2">{cusList.title}</h2>
                            <p className="fn-3">{cusList.desc}</p> 
                        </div>
                    </div>
                    <Slider {...settings}>
                        {cusList.CustomersList.map( (client, cliIndex) => 
                          <div key={cliIndex}>
                              <img src={client.imgUrl} alt={client.alt} />
                          </div> 
                        )}
                    </Slider>
                </Fragment>
              ))}
          </Container>
      </div>
    </>
   )
}

export default Clients;
import React, { useRef, useEffect } from 'react';
import "./VideoAutoPlay.scss";

const VideoAutoPlay = ({ videoSource, videoPoster, alt, videoShadow, radius }) => {

  const videoRef = useRef(null);
  const platforms = navigator.platform;
  const isVisibles = platforms === 'iPhone' || platforms === 'iPad' || platforms.includes('Mac') ;

  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 0.5, // Adjust this threshold as needed
  //   };

  //   const observer = new IntersectionObserver(handleIntersection, options);
  //   if (videoRef.current) {
  //     observer.observe(videoRef.current);
  //   }
  //   return () => {
  //     if (videoRef.current) {
  //       observer.unobserve(videoRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this threshold as needed
    };
  
    const observer = new IntersectionObserver(handleIntersection, options);
    const currentVideoRef = videoRef.current; // Store current value of videoRef.current in a variable
  
    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }
    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, [videoRef]);


  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error("Autoplay failed:", error);
        });
      } else if (videoRef.current) {
        videoRef.current.pause();
      }
    });
  };

  return (
    <>
      {isVisibles  ? (
          <img
          src={videoPoster}
          className="img-fluid"
          alt={alt}
          />         
          ):(
          <video
          // style={{ borderRadius: radius }}
          className={`video-auto-play ${videoShadow} ${radius}`}
          ref={videoRef} 
          controls
          loop
          muted>
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      }
    </>
  );
};

export default VideoAutoPlay;



import React from 'react';
import "./ClientDetails.scss";
import Container from 'react-bootstrap/Container';
import Slider from "react-slick";
import { ClientDetailsData } from './ClientDetailsData';

const ClientDetails = () => {
  
  const settings = {
      className: "slider",
      focusOnSelect: true,
      dots: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      // variableWidth: true,
      centerPadding:'10px',

      responsive: [
        {
          breakpoint: 992,
          settings: {
          slidesToShow: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
          slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
          slidesToShow: 1
          }
        },
        {
          breakpoint: 320,
          settings: {
          slidesToShow: 1
          }
        }
      ]
  };
  return (
    <>
      <div className="clients client-details">
          <Container>
                <div className="row justify-content-center align-items-center">
                <div className="col-lg-12 col-xl-12 col-xxl-10">
                <Slider {...settings}>
                    {ClientDetailsData.map((clientText, cliIndex)=>(
                      <div key={cliIndex}>
                        <div className="card">
                            <div className="card-body text-center">
                              <div className="max-height">
                                  <p>{clientText.desc}</p>
                              </div>
                              <img src={clientText.imgUrl} className="img-thumbnail rounded-circle" alt={clientText.title} />
                              <h4>{clientText.title}</h4>
                              <p className="text-magenta mb-0">{clientText.subTitle}</p>
                            </div>
                        </div>
                      </div>
                    ))}
                </Slider>
                </div>
              </div>
          </Container>
      </div>
    </>
  )
}

export default ClientDetails;
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const RouteSpecificMeta = ({ title, description, canonical, SchemaData, image }) => {
  const location = useLocation();
  const absoluteImageUrl = `${window.location.origin}${image}`;

  useEffect(() => {
    // console.log('Url', location.pathname);
    // console.log('Title', location.title);
    // console.log('Description', location.description);
    // console.log('Canonical', location.canonical);
    // console.log('Image URL:', image);
  }, [location.pathname,title,description,canonical,SchemaData,image ]);

  return (
    <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        {canonical && <link rel="canonical" href={canonical} />}
        {/* Open Graph Meta Tags */}
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content="QkrBiz" />
        <meta property="og:type" content="website" />
        {/* {image && <meta property="og:image" content={image} />} */}
        {image && <meta property="og:image" content={absoluteImageUrl} />}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
         {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@QkrbizDotCom" />
        <meta name="twitter:creator" content="@QkrbizDotCom" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* {image && <meta name="twitter:image" content={image} />} */}
        {image && <meta name="twitter:image" content={absoluteImageUrl} />}
        {/* JSON-LD Schema */}
        {SchemaData && (
          <script type="application/ld+json">
            {JSON.stringify(SchemaData)}
          </script>
        )}
  
    </Helmet>
  );
};

export default RouteSpecificMeta;
